//
export const getCustomerTree = (data, parent = null, level = 0) => {
  let customers = [];
  for (const id in data) {
    if (data[id].parent_id == parent) {
      customers.push({
        ...data[id],
        level: level,
        prefix: '--'.repeat(level),
      });
      const children = getCustomerTree(data, id, level + 1);
      for (const child_id in children) {
        customers.push({
          ...children[child_id],
          level: level + 1,
          prefix: '--'.repeat(level + 1),
        });
      }
    }
  }
  return customers;
};

export const getCustomerHierarchy = (data) => {
  let customers = [];

  for (const x in data['allowed']) {
    const parent_id = data['allowed'][x];
    var level = 0;
    if (parent_id != null) {
      customers.push({
        ...data['customers'][parent_id],
        level: 0,
        prefix: '',
      });
      level = 1;
    }

    const children = getCustomerTree(data['customers'], parent_id, level);
    for (const y in children) {
      customers.push(children[y]);
    }
  }
  return customers;
};

export const fetchCustomers = async (token) => {
  return await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customers', {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw response;
  });
};

export const fetchCustomer = async (customer_id, token) => {
  return await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customer/' + customer_id, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }).then((response) => {
    if (response.ok) {
      return response.json();
    }
    throw response;
  });
};
