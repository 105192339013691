// External Imports
import { useState } from 'react';
import { isExpired, decodeToken } from 'react-jwt';

// Hook: decode JWT token
export default function useToken() {
  // Get from Local Storage
  const getToken = () => {
    const tokenInCache = localStorage.getItem('token');
    if (tokenInCache !== null) {
      // Check Validity
      if (!isExpired(tokenInCache)) {
        return tokenInCache;
      } else {
        localStorage.clear();
      }
    }
    return '';
  };

  const getName = () => {
    const nameInCache = localStorage.getItem('name');
    if (nameInCache !== null) {
      return nameInCache;
    }
    return '';
  };

  const getGroups = () => {
    const tokenInCache = localStorage.getItem('token');
    if (tokenInCache !== null) {
      // Check Validity
      if (!isExpired(tokenInCache)) {
        const decodedToken = decodeToken(tokenInCache);
        return decodedToken.idp.groups;
      }
    }
    return [];
  };

  // Store in state
  const [token, setToken] = useState(getToken());
  const [name, setName] = useState(getName());
  //const [groups, setGroups] = useState([]);

  // Save to Local Storage
  const saveToken = (newToken) => {
    // Sanity check
    if (!isExpired(newToken)) {
      const decodedToken = decodeToken(newToken);
      const newName = decodedToken.idp.name;

      localStorage.setItem('token', newToken);
      localStorage.setItem('name', newName);

      setToken(newToken);
      setName(newName);
      //setGroups(newGroups);
    }
  };

  const isValidToken = () => {
    return getToken() !== '';
  };

  // Return values
  return {
    token,
    name,
    getGroups,
    setToken: saveToken,
    isValidToken,
  };
}
