// External Imports
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

//Hooks
import { useAuth } from 'react-oidc-context';

// Login Window
export default function Login() {
  const auth = useAuth();

  return (
    <>
      <Row className='mb-3'>
        <Col>
          <h1 className='text-center'>RCS Customer Portal: Log In</h1>
        </Col>
      </Row>
      <Row className='mb-3'>
        <Col sm={{ span: 6, offset: 3 }}>
          <Card>
            <Card.Header>Customer Log In</Card.Header>
            <Card.Body className='text-center'>
              {auth.error && (
                <Alert variant='danger'>
                  <span>{auth.error.message}</span>
                </Alert>
              )}
              {auth.isLoading ? (
                <Alert variant='warning'>
                  <span>Redirecting to Authentication.</span>
                </Alert>
              ) : (
                <Button variant='success' onClick={() => void auth.signinRedirect()}>
                  Log In
                </Button>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}
