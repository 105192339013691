// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import ProgressBar from 'react-bootstrap/ProgressBar';

// Hooks
import { useAuth } from 'react-oidc-context';
import useToken from '../utils/useToken';
import useBearerToken from '../utils/useBearerToken';
import { fetchCustomers, getCustomerHierarchy } from '../utils/customer';

//Header
export default function Header() {
  const auth = useAuth();
  const { token, name, isValidToken } = useToken();
  const { isLoggedIn, getFullName, getToken, logout } = useBearerToken();

  // Local Data
  const [loaded, setLoaded] = useState(false);
  const [status, setStatus] = useState({ variant: 'warning', size: 33, label: 'Loading' });
  const [customers, setCustomers] = useState([]);

  const getStatus = async () => {
    const bearerToken = getToken(auth, isValidToken, token);
    if (bearerToken == '') {
      return;
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/status', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data['queue']['latest_offset_stored'] == data['data']['offset']) {
          setStatus({
            variant: 'success',
            size: 100,
            label: 'Latest data available.',
          });
        } else {
          setStatus({
            variant: 'danger',
            size: 66,
            label: 'Data is outdated!',
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCustomers = async () => {
    const bearerToken = getToken(auth, isValidToken, token);
    if (bearerToken == '') {
      return;
    }

    await fetchCustomers(bearerToken)
      .then((data) => {
        const customerTree = getCustomerHierarchy(data);
        setCustomers(customerTree);
      })
      .catch((error) => {
        console.log('[' + error.status + '] ' + error.statusText);
      });

    setLoaded(true);
  };

  useEffect(() => {
    // OK: Only called once
    getStatus();
    getCustomers();
  }, [auth, token]);

  // Event Handlers
  const handleLogout = (event) => {
    logout(auth, isValidToken);
  };

  return (
    <Navbar expand='lg' className='bg-white mb-3' variant='light'>
      <Container>
        <Navbar.Brand href='/'>
          <Image src='https://static.rcswimax.com/images/logo_75px.png' width='50' height='50' />
          Customer Portal
        </Navbar.Brand>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        {isLoggedIn(auth, isValidToken) ? (
          <>
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='me-auto'>
                <NavDropdown title='Customer' id='basic-nav-dropdown'>
                  {loaded ? (
                    <>
                      {customers.map((customer) => (
                        <NavDropdown.Item
                          key={customer.customer_id}
                          href={'/customer/' + customer.customer_id}
                        >
                          {customer.prefix} {customer.name}
                        </NavDropdown.Item>
                      ))}
                    </>
                  ) : (
                    <NavDropdown.Item disabled='true'>Loading...</NavDropdown.Item>
                  )}
                </NavDropdown>
                <NavDropdown title={getFullName(auth, isValidToken, name)} id='basic-nav-dropdown'>
                  <NavDropdown.Item href='/logout' onClick={handleLogout}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
            <Navbar.Text className='d-flex'>
              <ProgressBar
                striped
                variant={status['variant']}
                now={status['size']}
                label={status['label']}
                style={{ width: 200 + 'px' }}
              />
            </Navbar.Text>
          </>
        ) : (
          <>
            <Navbar.Collapse id='basic-navbar-nav'>
              <Nav className='me-auto'>
                <Nav.Link href='/staff'>Staff Login</Nav.Link>
                <Nav.Link href='/customer'>Customer Login</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </>
        )}
      </Container>
    </Navbar>
  );
}
