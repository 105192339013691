// Imports
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { AuthProvider } from 'react-oidc-context';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import 'react-datetime/css/react-datetime.css';

import './style/App.css';

import App from './App';
import oidcConfig from './utils/oidc';
import reportWebVitals from './utils/reportWebVitals';

//Insert application into div element with id : "root"
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <StrictMode>
    <AuthProvider {...oidcConfig}>
      <App />
    </AuthProvider>
  </StrictMode>,
);

//Extra functionality: Performance logging
reportWebVitals();
