// External Imports
import React from 'react';

import Alert from 'react-bootstrap/Alert';

export default function AccessDenied() {
  return (
    <Alert variant='danger'>
      <span>You do not have sufficient permissions to access this page!</span>
    </Alert>
  );
}
