// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

import AccessDenied from './AccessDenied';

//Hooks
import { useAuth } from 'react-oidc-context';
import useToken from '../utils/useToken';
import useBearerToken from '../utils/useBearerToken';
import { fetchCustomer } from '../utils/customer';

export default function Customer() {
  const auth = useAuth();
  const { token, isValidToken } = useToken();
  const { isLoggedIn, getToken } = useBearerToken();
  const params = useParams();

  // Local Data
  const [customerId, setCustomerId] = useState(params.customerId);
  const [customer, setCustomer] = useState({});
  const [error, setError] = useState({ message: '', severity: 'primary', access_denied: false });
  const [loading, setLoading] = useState(true);

  const getCustomer = async () => {
    if (!isLoggedIn(auth, isValidToken)) {
      window.location.reload(false);
    }

    const bearerToken = getToken(auth, isValidToken, token);
    if (bearerToken == '') {
      return;
    }

    setLoading(true);

    await fetchCustomer(customerId, bearerToken)
      .then((data) => {
        setCustomer(data);
        setError({ message: '', severity: 'success', access_denied: false });
      })
      .catch((error) => {
        console.log(error);
        if (error.status == 403) {
          setError({ message: '', severity: 'danger', access_denied: true });
        } else {
          setError({
            message: 'Could not retrieve customer!',
            severity: 'danger',
            access_denied: false,
          });
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    getCustomer();
  }, [customerId]);

  return (
    <>
      {error.message != '' ? (
        <Alert variant={error.severity}>
          <span>{error.message}</span>
        </Alert>
      ) : (
        <>
          {error.access_denied ? (
            <AccessDenied />
          ) : (
            <Row>
              <Col>
                <h1 className='text-center'>
                  [{customerId}] {customer.name}
                </h1>
              </Col>
            </Row>
          )}
        </>
      )}

      {loading ? (
        <Alert variant='warning'>
          <Spinner animation='border' role='status'>
            <span className='visually-hidden'>Loading...</span>
          </Spinner>
          <span>Data loading...</span>
        </Alert>
      ) : (
        <>
          {customer.bandwidth_pools != null && customer.bandwidth_pools.length > 0 && (
            <Row className='mb-3'>
              <Col>
                <Card>
                  <Card.Header>Bandwidth Pools</Card.Header>
                  <Card.Body>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Bandwidth Pool</th>
                          <th>Description</th>
                          <th>Downlink</th>
                          <th>Uplink</th>
                          <th>Network</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customer.bandwidth_pools.map((pool) => (
                          <tr key={pool.pool_name}>
                            <th>
                              <a
                                className='btn btn-primary'
                                href={
                                  '/customer/' + customerId + '/bandwidth_pool/' + pool.pool_name
                                }
                              >
                                {pool.pool_name}
                              </a>
                            </th>
                            <td>{pool.pool_description}</td>
                            <td>{pool.downlink_mir / 1024} Mbps</td>
                            <td>{pool.uplink_mir / 1024} Mbps</td>
                            <td>{pool.uplink}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {customer.sites != null && customer.sites.length > 0 && (
            <Row className='mb-3'>
              <Col>
                <Card>
                  <Card.Header>Sites</Card.Header>
                  <Card.Body>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Site ID</th>
                          <th>Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customer.sites.map((site) => (
                          <tr key={site.site_id}>
                            <th>
                              <a
                                className='btn btn-primary'
                                href={'/customer/' + customerId + '/site/' + site.site_id}
                              >
                                {site.site_id}
                              </a>
                            </th>
                            <td>{site.name}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}

          {customer.subscriptions != null && customer.subscriptions.length > 0 && (
            <Row className='mb-3'>
              <Col>
                <Card>
                  <Card.Header>Subscriptions</Card.Header>
                  <Card.Body>
                    <Table striped>
                      <thead>
                        <tr>
                          <th>Site</th>
                          <th>Subnet</th>
                          <th>Status</th>
                          <th>Subscription</th>
                          <th>Bandwidth Pool</th>
                          <th>Network</th>
                        </tr>
                      </thead>
                      <tbody>
                        {customer.subscriptions.map((sub) => (
                          <tr key={sub.subnet}>
                            <td>{sub.site_id}</td>
                            <th>
                              <a
                                className='btn btn-primary'
                                href={
                                  '/customer/' +
                                  customerId +
                                  '/subscription/' +
                                  sub.subnet.split('/')[0]
                                }
                              >
                                {sub.subnet}
                              </a>
                            </th>
                            <td>{sub.status}</td>
                            <td>{sub.plan_name}</td>
                            {customer.bandwidth_pools.findIndex(
                              (v) => v.pool_name === sub.pool_name,
                            ) > -1 ? (
                              <>
                                <td>{sub.pool_name} </td>
                                <td>&nbsp;</td>
                              </>
                            ) : (
                              <>
                                <td>&nbsp;</td>
                                <td>{sub.pool_name}</td>
                              </>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
}
