// External Imports
import { useState } from 'react';

// Hook: Retrieve JWT token from any source
export default function useBearerToken() {
  const isLoggedIn = (auth, isValidToken) => {
    // Staff Login
    if (isValidToken()) {
      return true;
    }
    // Customer Login
    if (auth.isAuthenticated) {
      return true;
    }
    return false;
  };

  const getFullName = (auth, isValidToken, name) => {
    // Staff Login
    if (isValidToken()) {
      return name;
    }
    // Customer Login
    if (auth.isAuthenticated) {
      return auth.user.profile.name;
    }
    return false;
  };

  const getToken = (auth, isValidToken, token) => {
    // Staff Login
    if (isValidToken()) {
      return token;
    }
    // Customer Login
    if (auth.isAuthenticated) {
      return auth.user.access_token;
    }
    return '';
  };

  const logout = (auth, isValidToken) => {
    // Staff Login
    if (isValidToken()) {
      localStorage.clear();
    }
    // Customer Login
    if (auth.isAuthenticated) {
      auth.removeUser();
    }
  };

  // Public Interface
  return {
    isLoggedIn,
    getToken,
    getFullName,
    logout,
  };
}
