// External Imports
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import Chart from 'react-apexcharts';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import Tooltip from 'react-bootstrap/Tooltip';

import Datetime from 'react-datetime';

import * as formik from 'formik';
import * as yup from 'yup';

import AccessDenied from './AccessDenied';

//Hooks
import { useAuth } from 'react-oidc-context';
import useToken from '../utils/useToken';
import useBearerToken from '../utils/useBearerToken';

export default function Bandwidth({ level }) {
  const auth = useAuth();
  const { token, isValidToken } = useToken();
  const { isLoggedIn, getToken } = useBearerToken();
  const params = useParams();

  // Local Data
  const [customerId, setCustomerId] = useState(params.customerId);
  const [subnet, setSubnet] = useState(params.ip);

  const [page, setPage] = useState('utilisation');
  const [pages, setPages] = useState({});
  const [selectedPercentile, selectPercentile] = useState(50);

  let zoomLoading = useRef(false);
  let utilisationData = useRef({});
  let zoomData = useRef({});

  const [error, setError] = useState({
    message: 'Please select the timeframe.',
    severity: 'info',
    access_denied: false,
  });
  const [loading, setLoading] = useState(false);

  const { Formik } = formik;
  const now = new Date();
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);

  const schema = yup.object().shape({
    from_time: yup.date().required(), // TODO?
    to_time: yup.date().required(), // TODO?
  });

  const getSubnets = async () => {
    if (!isLoggedIn(auth, isValidToken)) {
      window.location.reload(false);
    }

    const bearerToken = getToken(auth, isValidToken, token);
    if (bearerToken == '') {
      return;
    }

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/customer/' + customerId + '/hosts', {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (level == 'subscription') {
          if (params.ip in data) {
            setSubnet(data[params.ip]);
          } else {
            setError({ message: '', severity: 'danger', access_denied: true });
          }
        }
      })
      .catch((error) => {
        if (error.status == 403) {
          setError({ message: '', severity: 'danger', access_denied: true });
        } /* else {
        setError({ message: 'Could not retrieve customer hosts!', severity: 'danger', access_denied: false });
      }*/
      });
  };

  useEffect(() => {
    getSubnets();
  }, []);

  const formatBandwidth = (value) => {
    if (value < 1024) {
      return Math.round(value) + ' kbps';
    }
    return Math.round(value / 1024) + ' Mbps';
  };
  const formatPercentage = (value) => {
    return Math.round(value) + ' %';
  };
  const formatSimple = (value) => {
    return Math.round(value);
  };

  const getOptionsTs = (id, title, metric, formatter, allowZoom = true) => {
    return {
      chart: {
        id: id,
        events: {
          zoomed: onChartZoom,
        },
        toolbar: {
          tools: {
            zoom: allowZoom,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: allowZoom,
          },
        },
      },
      title: {
        text: title,
      },
      xaxis: {
        categories: Object.keys(metric),
      },
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: formatter,
        },
      },
    };
  };

  const getOptionsPie = (id, title, metric, formatter) => {
    return {
      chart: {
        id: id,
        toolbar: {
          show: true,
        },
      },
      title: {
        text: title,
      },
      labels: Object.keys(metric),
      dataLabels: {
        enabled: false,
      },
      yaxis: {
        labels: {
          formatter: formatter,
        },
      },
    };
  };

  const getOptionsTree = (id, title, formatter) => {
    return {
      chart: {
        id: id,
      },
      title: {
        text: title,
      },
      yaxis: {
        labels: {
          formatter: formatter,
        },
      },
    };
  };

  const getSeries = (data, kv) => {
    return Object.keys(kv).map((k) => {
      return {
        name: k,
        data: Object.values(data[kv[k]]),
      };
    });
  };

  const getTreeSeries = (series) => {
    const tree = Object.keys(series).map((name) => {
      return {
        x: name,
        y: series[name],
      };
    });

    return [{ data: tree }];
  };

  const getUtilisation = async (data) => {
    if (!isLoggedIn(auth, isValidToken)) {
      window.location.reload(false);
    }

    const bearerToken = getToken(auth, isValidToken, token);
    if (bearerToken == '') {
      return;
    }

    setLoading(true);
    setError({ message: '', severity: 'success', access_denied: false });

    await fetch(process.env.REACT_APP_BACKEND_URL + '/v1/utilisation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        const pages = {};

        if (level == 'bandwidth_pool') {
          if (data['utilisation'] != false) {
            pages['utilisation'] = {
              charts: [
                {
                  key: 'rates',
                  col_size: 12,
                  type: 'line',
                  options: getOptionsTs(
                    'rates',
                    'Bandwidth (bits/s)',
                    data['utilisation']['rate_destination'],
                    formatBandwidth,
                  ),
                  series: getSeries(data['utilisation'], {
                    downstream: 'rate_destination',
                    upstream: 'rate_source',
                  }),
                },
              ],
            };
            utilisationData.current = data['utilisation'];
            zoomData.current = data['utilisation'];
          }

          if (data['protocol_groups'] != false) {
            pages['application groups'] = {
              charts: [
                {
                  key: 'ds_tree',
                  col_size: 6,
                  type: 'treemap',
                  options: getOptionsTree('ds_tree', 'Downstream', formatPercentage),
                  series: getTreeSeries(data['protocol_groups']['byte_count_destination']),
                },
                {
                  key: 'us_tree',
                  col_size: 6,
                  type: 'treemap',
                  options: getOptionsTree('us_tree', 'Upstream', formatPercentage),
                  series: getTreeSeries(data['protocol_groups']['byte_count_source']),
                },
              ],
            };
          }

          if (data['protocols'] != false) {
            pages['applications'] = {
              charts: [
                {
                  key: 'ds_pie',
                  col_size: 6,
                  type: 'pie',
                  options: getOptionsPie(
                    'ds_pie',
                    'Downstream',
                    data['protocols']['byte_count_destination'],
                    formatPercentage,
                  ),
                  series: Object.values(data['protocols']['byte_count_destination']),
                },
                {
                  key: 'us_pie',
                  col_size: 6,
                  type: 'pie',
                  options: getOptionsPie(
                    'us_pie',
                    'Upstream',
                    data['protocols']['byte_count_source'],
                    formatPercentage,
                  ),
                  series: Object.values(data['protocols']['byte_count_source']),
                },
              ],
            };
          }
        }

        if (level == 'site') {
          if (data['utilisation'] != false) {
            pages['utilisation'] = {
              charts: [
                {
                  key: 'rates',
                  col_size: 12,
                  type: 'line',
                  options: getOptionsTs(
                    'rates',
                    'Bandwidth (bits/s)',
                    data['utilisation']['rate_destination'],
                    formatBandwidth,
                  ),
                  series: getSeries(data['utilisation'], {
                    downstream: 'rate_destination',
                    upstream: 'rate_source',
                  }),
                },
              ],
            };
            utilisationData.current = data['utilisation'];
            zoomData.current = data['utilisation'];
          }

          if (data['protocol_groups'] != false) {
            pages['application groups'] = {
              charts: [
                {
                  key: 'ds_tree',
                  col_size: 6,
                  type: 'treemap',
                  options: getOptionsTree('ds_tree', 'Downstream', formatPercentage),
                  series: getTreeSeries(data['protocol_groups']['byte_count_destination']),
                },
                {
                  key: 'us_tree',
                  col_size: 6,
                  type: 'treemap',
                  options: getOptionsTree('us_tree', 'Upstream', formatPercentage),
                  series: getTreeSeries(data['protocol_groups']['byte_count_source']),
                },
              ],
            };
          }

          if (data['protocols'] != false) {
            pages['applications'] = {
              charts: [
                {
                  key: 'ds_pie',
                  col_size: 6,
                  type: 'pie',
                  options: getOptionsPie(
                    'ds_pie',
                    'Downstream',
                    data['protocols']['byte_count_destination'],
                    formatPercentage,
                  ),
                  series: Object.values(data['protocols']['byte_count_destination']),
                },
                {
                  key: 'us_pie',
                  col_size: 6,
                  type: 'pie',
                  options: getOptionsPie(
                    'us_pie',
                    'Upstream',
                    data['protocols']['byte_count_source'],
                    formatPercentage,
                  ),
                  series: Object.values(data['protocols']['byte_count_source']),
                },
              ],
            };
          }

          if (data['geolocations'] != false) {
            pages['geolocations'] = {
              charts: [
                {
                  key: 'tree',
                  col_size: 12,
                  type: 'treemap',
                  options: getOptionsTree('tree', 'Total', formatPercentage),
                  series: getTreeSeries(data['geolocations']['rate_total']),
                },
              ],
            };
          }
        }

        if (level == 'subscription') {
          if (data['utilisation'] != false) {
            pages['utilisation'] = {
              charts: [
                {
                  key: 'rates',
                  col_size: 12,
                  type: 'line',
                  options: getOptionsTs(
                    'rates',
                    'Bandwidth (bits/s)',
                    data['utilisation']['rate_destination'],
                    formatBandwidth,
                  ),
                  series: getSeries(data['utilisation'], {
                    downstream: 'rate_destination',
                    upstream: 'rate_source',
                  }),
                },
              ],
            };
            utilisationData.current = data['utilisation'];
            zoomData.current = data['utilisation'];
          }

          if (data['protocols'] != false) {
            pages['applications'] = {
              charts: [
                {
                  key: 'ds_tree',
                  col_size: 12,
                  type: 'treemap',
                  options: getOptionsTree('ds_tree', 'Total', formatPercentage),
                  series: getTreeSeries(data['protocols']['rate_total']),
                },
              ],
            };
          }
        }

        setPages(pages);
        setLoading(false);

        if (data['utilisation'] == false && data['protocols'] == false) {
          setError({
            message: 'No data available for this timeframe!',
            severity: 'warning',
            access_denied: false,
          });
        } else {
          setError({ message: '', severity: 'success', access_denied: false });
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.status == 403) {
          setError({ message: '', severity: 'danger', access_denied: true });
        } else {
          setError({
            message: 'Could not retrieve utilisation!',
            severity: 'danger',
            access_denied: false,
          });
        }
      });
  };

  const getPostData = () => {
    const postData = {
      customer_id: customerId,
    };
    if (level == 'bandwidth_pool') {
      postData['bandwidth_pool'] = params.poolName;
    }
    if (level == 'site') {
      postData['site_id'] = params.siteId;
    }
    if (level == 'subscription') {
      postData['ip'] = params.ip;
    }
    return postData;
  };

  // Event Handlers
  const handleSelect = (eventKey) => setPage(eventKey);

  // Zoom
  const onChartZoom = (chartContext, { xaxis, yaxis }) => {
    if (xaxis.min == undefined) {
      console.log('Resetting Zoom Level...'); //DEBUG

      //Store in State
      zoomData.current = utilisationData.current;

      chartContext.updateOptions({
        series: getSeries(utilisationData.current, {
          downstream: 'rate_destination',
          upstream: 'rate_source',
        }),
        xaxis: {
          categories: Object.keys(utilisationData.current.rate_destination),
        },
      });
    } else {
      console.log('Reloading data for zoomed timeframe...'); //DEBUG
      zoomLoading.current = true

      if (zoomData.current.rate_destination == undefined) {
        console.log('BUG! zoomData not yet set..');
        return;
      }

      var index = 1;
      var from = undefined;
      var to = undefined;
      for (const ts of Object.keys(zoomData.current.rate_destination)) {
        if (index == xaxis.min) {
          from = ts;
        }
        if (index == xaxis.max) {
          to = ts;
        }
        index++;
      }
      if (from == undefined || to == undefined) {
        console.log('BUG! Times not selected: ' + from + ' - ' + to);
        return;
      }

      // Fetch data
      const data = getPostData();
      data['from_time'] = Math.round(Date.parse(from + 'Z') / 1000);
      data['to_time'] = Math.round(Date.parse(to + 'Z') / 1000);
      data['percentile'] = selectedPercentile;
      getZoomedUtilisation(chartContext, data);
    }
  };

  const getZoomedUtilisation = async (chartContext, data) => {
    if (!isLoggedIn(auth, isValidToken)) {
      window.location.reload(false);
    }

    setLoading(true);
    setError({ message: '', severity: 'success', access_denied: false });

    const bearerToken = getToken(auth, isValidToken, token);
    if (bearerToken == '') {
      return;
    }

    fetch(process.env.REACT_APP_BACKEND_URL + '/v1/utilisation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + bearerToken,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw response;
      })
      .then((data) => {
        if (data['utilisation'] != false) {
          setError({ message: '', severity: 'success', access_denied: false });
          return data['utilisation'];
        } else {
          setError({
            message: 'No data available for this timeframe!',
            severity: 'warning',
            access_denied: false,
          });
        }
      })
      .then((data) => {
        //Store in State
        zoomData.current = data;
        
        console.log('Zoomed data loaded...'); //DEBUG
        zoomLoading.current = false
        
        // Update chart
        chartContext.updateOptions({
          series: getSeries(data, {
            downstream: 'rate_destination',
            upstream: 'rate_source',
          }),
          xaxis: {
            categories: Object.keys(data.rate_destination),
          },
        });
      })
      .catch((error) => {
        console.log(error);

        if (error.status == 403) {
          setError({ message: '', severity: 'danger', access_denied: true });
        } else {
          setError({
            message: 'Could not retrieve utilisation!',
            severity: 'danger',
            access_denied: false,
          });
        }
      });

    setLoading(false);
  };

  //Form Handler
  const handleForm = async (values, { resetForm }) => {
    if (!auth.isAuthenticated && !isValidToken()) {
      window.location.reload(false);
    }

    selectPercentile(values.percentile);

    const data = getPostData();
    data['from_time'] = Math.round(values.from_time / 1000);
    data['to_time'] = Math.round(values.to_time / 1000);
    data['percentile'] = selectedPercentile;
    getUtilisation(data);
  };

  return (
    <>
      {error.access_denied ? (
        <Row>
          <Col>
            <AccessDenied />
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col>
              <h1 className='text-center'>
                Bandwidth Utilisation for
                {level == 'bandwidth_pool' && <> {params.poolName}</>}
                {level == 'site' && <> {params.siteId}</>}
                {level == 'subscription' && <> {subnet}</>}
              </h1>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className='mb-3'>
                <Card.Header>Data Selection</Card.Header>
                <Card.Body>
                  <Formik
                    validationSchema={schema}
                    onSubmit={handleForm}
                    initialValues={{
                      from_time: yesterday,
                      to_time: now,
                      percentile: 50,
                    }}
                  >
                    {({ handleSubmit, handleChange, setFieldValue, values, touched, errors }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col sm={3}>
                            <Form.Group controlId='from_time'>
                              <OverlayTrigger
                                overlay={<Tooltip id='from_time'>All times in UTC/GMT!</Tooltip>}
                              >
                                <Form.Label>From Time</Form.Label>
                              </OverlayTrigger>
                              <Datetime
                                value={values.from_time}
                                dateFormat='YYYY-MM-DD'
                                timeFormat='HH:mm'
                                utc='true'
                                onChange={async (ms) => {
                                  setFieldValue('from_time', ms); // TODO FORMATTING ?
                                }}
                              />
                              <Form.Control
                                type='hidden'
                                name='from_time'
                                value={values.from_time}
                                isInvalid={!!errors.from_time}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.from_time}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId='to_time'>
                              <OverlayTrigger
                                overlay={<Tooltip id='to_time'>All times in UTC/GMT!</Tooltip>}
                              >
                                <Form.Label>To Time</Form.Label>
                              </OverlayTrigger>
                              <Datetime
                                value={values.to_time}
                                dateFormat='YYYY-MM-DD'
                                timeFormat='HH:mm'
                                utc='true'
                                onChange={async (ms) => {
                                  setFieldValue('to_time', ms); // TODO FORMATTING ?
                                }}
                              />
                              <Form.Control
                                type='hidden'
                                name='to_time'
                                value={values.to_time}
                                isInvalid={!!errors.to_time}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type='invalid'>
                                {errors.to_time}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={3}>
                            <Form.Group controlId='percentile'>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id='percentile'>
                                    The data in the graph is averaged out over the resolution
                                    timeframe. Select 99-th percentile to see maximum values in the
                                    resolution timeframe.
                                  </Tooltip>
                                }
                              >
                                <Form.Label>Percentile</Form.Label>
                              </OverlayTrigger>
                              <Form.Select
                                aria-label='Select the percentile'
                                name='percentile'
                                value={values.percentile}
                                isInvalid={!!errors.percentile}
                                onChange={handleChange}
                              >
                                <option value='50'>50-th % (average/mean)</option>
                                <option value='75'>75-th %</option>
                                <option value='90'>90-th %</option>
                                <option value='95'>95-th %</option>
                                <option value='99'>99-th % (maximum)</option>
                              </Form.Select>
                              <Form.Control.Feedback type='invalid'>
                                {errors.percentile}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col sm={2}>
                            <Form.Group controlId='button'>
                              <Form.Label>&nbsp;</Form.Label>
                              <div>
                                <Button variant='success' type='submit'>
                                  Show
                                </Button>
                              </div>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <Card.Header>
                  {loading ? (
                    <span>Loading</span>
                  ) : error.message != '' ? (
                    <span>No Data</span>
                  ) : (
                    <Nav variant='tabs' onSelect={handleSelect} defaultActiveKey={page}>
                      {Object.keys(pages).map((page) => (
                        <Nav.Item key={page}>
                          <Nav.Link eventKey={page}>
                            {page.charAt(0).toUpperCase() + page.slice(1)}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  )}
                </Card.Header>
                <Card.Body>
                  { zoomLoading.current &&
                    <Alert variant='warning'>
                      <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </Spinner>
                      <span>
                        Loading zoomed timeframe...
                      </span>
                    </Alert>
                  }         
                  {loading && (
                    <Alert variant='warning'>
                      <Spinner animation='border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </Spinner>
                      <span>
                        Data loading... This could take some time when the selected timeframe is
                        long.
                      </span>
                      {/* TODO: PROGRESS BAR ?? */}
                    </Alert>
                  )}
                  {error.message != '' && (
                    <Alert variant={error.severity}>
                      <span>{error.message}</span>
                    </Alert>
                  )}
                  <div>
                    {Object.keys(pages).map((p) => (
                      <Row key={p}>
                        {page == p && (
                          <>
                            {pages[p]['charts'].map((chart) => (
                              <Col key={chart['key']} sm={chart['col_size']}>
                                <Chart
                                  type={chart['type']}
                                  options={chart['options']}
                                  series={chart['series']}
                                  width='100%'
                                />
                              </Col>
                            ))}
                          </>
                        )}
                      </Row>
                    ))}
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

Bandwidth.propTypes = {
  level: PropTypes.string,
};
