const onSigninCallback = (_user) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_URL,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,
  redirect_uri: process.env.REACT_APP_FRONTEND_URL + '/auth-callback',
  onSigninCallback: onSigninCallback,
};

export default oidcConfig;
