// External Imports
import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import Alert from 'react-bootstrap/Alert';
import Container from 'react-bootstrap/Container';

// Components
import Header from './components/Header';
import Login from './components/Login';
import StaffLogin from './components/StaffLogin';

import Bandwidth from './components/Bandwidth';
import Customer from './components/Customer';
import Customers from './components/Customers';

// Hooks
import { useAuth } from 'react-oidc-context';
import useToken from './utils/useToken';
import { getCustomerHierarchy } from './utils/customer';

// Main application
export default function App() {
  const auth = useAuth();
  const { token, setToken, isValidToken } = useToken();

  return (
    <div>
      <Header />
      <Container>
        <div>
          {auth.activeNavigator == 'signinSilent' && (
            <Alert variant='success'>
              <span>Signing you in...</span>
            </Alert>
          )}
          {auth.activeNavigator == 'signoutRedirect' && (
            <Alert variant='warning'>
              <span>Signing you out...</span>
            </Alert>
          )}
        </div>

        {/* This is not an SPA ... */}
        {auth.isAuthenticated || isValidToken() ? (
          <BrowserRouter>
            <Routes>
              <Route
                path='/customer/:customerId/bandwidth_pool/:poolName'
                element={<Bandwidth level='bandwidth_pool' />}
              />
              <Route
                path='/customer/:customerId/site/:siteId'
                element={<Bandwidth level='site' />}
              />
              <Route
                path='/customer/:customerId/subscription/:ip'
                element={<Bandwidth level='subscription' />}
              />
              <Route path='/customer/:customerId' element={<Customer />} />
              <Route path='*' element={<Customers />} />
            </Routes>
          </BrowserRouter>
        ) : (
          <>
            <BrowserRouter>
              <Routes>
                <Route path='/staff' element={<StaffLogin setToken={setToken} />} />
                <Route path='*' element={<Login />} />
              </Routes>
            </BrowserRouter>
          </>
        )}
      </Container>
    </div>
  );
}
