// External Imports
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Alert from 'react-bootstrap/Alert';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';

//Hooks
import { useAuth } from 'react-oidc-context';
import useToken from '../utils/useToken';
import useBearerToken from '../utils/useBearerToken';
import { fetchCustomers, getCustomerHierarchy } from '../utils/customer';

export default function Customers() {
  const auth = useAuth();
  const { token, isValidToken } = useToken();
  const { isLoggedIn, getToken } = useBearerToken();

  // Local Data
  const [customers, setCustomers] = useState([]);
  const [error, setError] = useState({ message: '', severity: 'primary' });
  const [loading, setLoading] = useState(true);

  const getCustomers = async () => {
    const bearerToken = getToken(auth, isValidToken, token);
    if (bearerToken == '') {
      return;
    }

    setLoading(true);

    await fetchCustomers(bearerToken)
      .then((data) => {
        const customerTree = getCustomerHierarchy(data);
        setCustomers(customerTree);
        setError({ message: '', severity: 'success' });
      })
      .catch((error) => {
        if (error.status == 403) {
          setError({
            message:
              'Your account has not been initialised! Please contact support@rcs-southsudan.com',
            severity: 'warning',
          });
        } else {
          console.log('[' + error.status + '] ' + error.statusText);
          setError({ message: 'Could not retrieve customers!', severity: 'danger' });
        }
      });

    setLoading(false);
  };

  useEffect(() => {
    getCustomers();
  }, [auth, token]);

  return (
    <>
      {error.message != '' ? (
        <Row className='mb-3'>
          <Col>
            <Alert variant={error.severity}>
              <span>{error.message}</span>
            </Alert>
          </Col>
        </Row>
      ) : (
        <Row className='mb-3'>
          <Col>
            <Card>
              <Card.Header>Please select the customer you want to access</Card.Header>
              <Card.Body>
                {loading ? (
                  <Alert variant='warning'>
                    <Spinner animation='border' role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </Spinner>
                    <span>Data loading...</span>
                  </Alert>
                ) : (
                  <>
                    {customers.length == 0 ? (
                      <Alert variant='warning'>
                        <span>
                          Your account does not have access to any customers! Please contact
                          support@rcs-southsudan.com
                        </span>
                      </Alert>
                    ) : (
                      <Table striped>
                        <thead>
                          <tr>
                            <th>Customer ID</th>
                            <th>Customer Name</th>
                          </tr>
                        </thead>
                        <tbody>
                          {customers.map((customer) => (
                            <tr key={customer.customer_id} className='mb-2'>
                              <th>
                                {customer.prefix}{' '}
                                <a
                                  href={'/customer/' + customer.customer_id}
                                  className='btn btn-primary'
                                >
                                  {customer.customer_id}
                                </a>
                              </th>
                              <td>{customer.name}</td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    )}
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
}
